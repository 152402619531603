import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Card, CardHeader, Typography, useTheme } from '@mui/material';
import CenteredBox from '@/app/base-ui/CenterBox';
import EllipsisTypography from '@/app/base-ui/EllipsisTypography';
import { ImgEmpty, IsExpert, IsInfluencerIcon, IsStaff } from '@/public/icon';
import UserModel from '@/app/models/user/UserModel';
import { formatTime } from '@/app/helpers/formatTime';
import { useRouter } from 'next/navigation';
import { PostModel } from '@/app/models/posts/Posts';
import EmptyMessageHome from '@/app/components/MUI/EmptyMessageHome';
import PostCard from './PostCard';
import { IMedia } from '@/app/models/media/IMedia';
import PostCardWithoutMedia from './PostCardWithOutMedia';
import YouTubeThumbnail from '@/app/components/Writing/UrlFormWriting/components/YouTubeThumbnail';
import Masonry from 'react-masonry-css';
import { MAX_WIDTH_POPULAR_POST } from '@/app/constants/layoutConfig';

const gutterSpace = '20px';

const columnClassName = 'my-masonry-grid_column';

const masonryStyles: any = {
    ml: `-${gutterSpace}`,
    display: 'flex',
    flexDirection: 'row',
    rowGap: '30px',
};

const selector = `& .${columnClassName}`;

masonryStyles[selector] = {
    pl: gutterSpace,
    backgroundClip: 'padding-box',
};

const PopularPosts = ({ posts = [] }: { posts: PostModel[] | any[] }) => {
    const router = useRouter();
    const theme = useTheme();

    const gotoPost = (id: string): void => {
        if (id) {
            router.push(`/community/posts/${id}`, { scroll: false });
        }
    };

    const TypeUserIcons = {
        is_expert: (
            <IsExpert
                width="14px"
                height="14px"
            />
        ),
        is_influencer: (
            <IsInfluencerIcon
                width="14px"
                height="14px"
            />
        ),
        is_staff: (
            <IsStaff
                width="14px"
                height="14px"
            />
        ),
    };

    const renderUserIcon = (userData: UserModel | null) => {
        if (userData?.isStaff) return TypeUserIcons.is_staff;
        if (userData?.isInfluencer) return TypeUserIcons.is_influencer;
        if (userData?.isExpert) return TypeUserIcons.is_expert;
        return null;
    };

    const breakpointColumnsObj = {
        350: 1,
        468: 1,
        751: 2,
    };

    const renderPosts = useMemo(() => {
        return posts.map((slide, index) => {
            const typeUrl: IMedia = slide?.media?.find((m: IMedia) => m.mediaType === 'url');
            const hasNoMedia = slide?.media.filter((m: IMedia) => m.mediaType !== 'file').length < 1;
            const hasFileAttachment = slide?.media.find((m: IMedia) => m.mediaType === 'file');
            return (
                <Box
                    component="div"
                    key={index}
                    onClick={() => gotoPost(slide.id)}
                >
                    <Card sx={{ width: '100%', background: 'none', boxShadow: 'none' }}>
                        {hasNoMedia ? (
                            Boolean(hasFileAttachment?.id) ? (
                                <PostCardWithoutMedia
                                    content={hasFileAttachment?.fileName ?? ''}
                                    height={210}
                                    borderRadius={4}
                                />
                            ) : (
                                <PostCardWithoutMedia
                                    content={slide?.caption ?? ''}
                                    height={210}
                                    borderRadius={4}
                                />
                            )
                        ) : typeUrl && typeUrl.mediaUrl ? (
                            <YouTubeThumbnail
                                sx={{ height: 210, marginTop: 0, display: 'flex' }}
                                url={typeUrl.mediaUrl}
                            />
                        ) : (
                            <PostCard
                                src={slide?.media?.[0]?.mediaUrl ?? ''}
                                alt={slide?.caption ?? ''}
                                height={210}
                                borderRadius={4}
                            />
                        )}

                        <CardHeader
                            sx={{
                                width: '100%',
                                pt: 3.5,
                                pb: 0,
                                px: 0,
                                gap: '10px',
                                '& .MuiCardHeader-avatar': { marginRight: 0 },
                            }}
                            avatar={
                                slide.owner.avatarUrl.avatar_og_url ? (
                                    <Avatar
                                        src={slide.owner.avatarUrl.avatar_og_url || ''}
                                        sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                    />
                                ) : (
                                    <Avatar
                                        sx={{
                                            bgcolor: theme.palette.background.paper,
                                            width: 40,
                                            height: 40,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <ImgEmpty
                                            width={'20px'}
                                            height={'20px'}
                                        />
                                    </Avatar>
                                )
                            }
                            title={
                                <Box
                                    component="span"
                                    sx={{ display: 'flex', lineHeight: '100%', cursor: 'pointer' }}
                                >
                                    <EllipsisTypography
                                        lineNumber={1}
                                        variant="h2_12Bold"
                                        mr={1}
                                    >
                                        {slide.owner.nickname ?? 'UNKNOWN'}
                                    </EllipsisTypography>
                                    {renderUserIcon(slide?.owner)}
                                </Box>
                            }
                            subheader={
                                <Typography
                                    variant="h2_10Regular"
                                    component="p"
                                    mt={1.5}
                                    lineHeight="100%"
                                    color={'var(--text-gray-05999, #999)'}
                                >
                                    {formatTime(slide.createdAt)}
                                </Typography>
                            }
                        />
                    </Card>
                </Box>
            );
        });
    }, [posts]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            <CenteredBox sx={{ justifyContent: 'space-between' }}>
                <Typography
                    variant="h2_18Bold"
                    lineHeight="100%"
                >
                    와글와글 커뮤니티 인기글
                </Typography>
            </CenteredBox>

            {posts.length < 1 ? (
                <Box sx={{ width: '100%' }}>
                    <EmptyMessageHome message="최근 12시간 내 작성된 인기글이 없습니다." />
                </Box>
            ) : (
                <Box
                    component={Masonry}
                    columnClassName={columnClassName}
                    breakpointCols={breakpointColumnsObj}
                    sx={{
                        '& .my-masonry-grid_column > div': {
                            marginBottom: '30px',
                            '&:last-of-type': {
                                marginBottom: 0,
                            },
                        },

                        ...masonryStyles,
                    }}
                    className=""
                >
                    {renderPosts}
                </Box>
            )}
        </Box>
    );
};

export default PopularPosts;
