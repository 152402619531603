"use client"
import { Card, CardProps, useTheme } from '@mui/material';

const BaseCard = (props: CardProps) => {
    const theme = useTheme();
    const { children, sx = {}, ...other } = props;

    return <Card sx={{ background: theme.palette.background.default, px: 0, boxShadow: 'none', borderRadius: '0', ...sx }}>{children}</Card>;
};

export default BaseCard;
