import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { logger } from '@/app/lib/logger';
import utilsService from '@/app/helpers/utilsService';
import { PostTypeConfig } from '@/app/constants/typedef';
import { FeedManager, FetchMethod } from '../pattern/ClassManagerMethodPost';
import { useSnackbar } from '@/app/contexts/snack-bar-provider';
import { actionFeedServices } from '@/app/service/actionFeedService';
import { PostModel } from '../models/posts/Posts';
import { useParams } from 'next/navigation';

interface PostsProps {
    selectedButton?: string;
    type?: PostTypeConfig;
    groupId?: string;
    perPage?: number | string;
}

const usePosts = ({ selectedButton, type = PostTypeConfig.Challenges, groupId, perPage = 20 }: PostsProps) => {
    const [posts, setPosts] = useState<any[]>([]);
    const [cursor, setCursor] = useState<string | null>(null);
    const totalPosts = useRef<number>(0);
    const { id: partnerId } = useParams();
    const [hasError, setHasError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const feedManager = useRef(new FeedManager(perPage as number)).current;
    const fetchController = useRef<AbortController | null>(null);
    const hasNext = useMemo(() => cursor !== null, [posts]);
    const { addSnackbar, setMarginBottom } = useSnackbar();
    useEffect(() => {
        setMarginBottom(20);
    }, []);
    const fetchPosts = async (filter: string | undefined, reset?: boolean) => {
        if (fetchController.current) {
            fetchController.current.abort();
        }
        fetchController.current = new AbortController();
        const signal = fetchController.current.signal;
        try {
            setIsLoading(true);
            const method = getFetchMethod(type);
            const optionData: any = { type, filter, partnerId };
            if (groupId) optionData['groupId'] = groupId;
            const { data, total, cursor: cur } = await feedManager.fetchDataForFeed<any>(method, optionData, reset ? null : cursor, signal);
            totalPosts.current = total;
            setCursor(cur ?? null);
            setPosts((prevPosts) => (reset ? data : [...prevPosts, ...data]));
            setIsLoading(false);
            setHasError(false);
        } catch (error: any) {
            const errorMessage = utilsService.getErrorMessage(error);
            if (errorMessage !== 'canceled') {
                setIsLoading(false);
                setCursor(null);
                setHasError(true);
                addSnackbar({
                    message: errorMessage || 'Some thing went wrong',
                    severity: 'error',
                    autoHideDuration: 3000,
                    vertical: 'bottom',
                    horizontal: 'center',
                    type: 'toast',
                });
            }
        }
    };

    const getFetchMethod = (type: string): FetchMethod => {
        switch (type) {
            case PostTypeConfig.Challenges:
                return FetchMethod.challenges;
            case PostTypeConfig.Posts:
                return FetchMethod.posts;
            case PostTypeConfig.fetchForMyChannel:
                return FetchMethod.fetchForMyChannel;
            case PostTypeConfig.fetchForHome:
                return FetchMethod.fetchForHome;
            case PostTypeConfig.fetchForPartner:
                return FetchMethod.fetchForPartner;
            default:
                throw new Error('Unknown filter');
        }
    };

    useEffect(() => {
        setPosts([]);
        fetchPosts(selectedButton, true);
    }, [selectedButton, type, groupId]);

    const loadMore = useCallback(async () => {
        if (cursor && !isLoading && hasNext) {
            await fetchPosts(selectedButton);
        }
    }, [posts, cursor, isLoading, selectedButton]);

    const hiddenPost = async (id: string) => {
        try {
            await setPosts((prevPosts) => prevPosts.filter((post) => post.id !== id));
        } catch (error) {
            const errorMessage = utilsService.getErrorMessage(error);
            addSnackbar({
                message: errorMessage || 'Some thing went wrong',
                severity: 'error',
                autoHideDuration: 3000,
                vertical: 'bottom',
                horizontal: 'center',
                type: 'toast',
            });
        }
    };

    const deletePost = async (id: string) => {
        try {
            await actionFeedServices.deleteFeedForOnlyPosts({ type: 'posts', id });
            setPosts((prevPosts) => prevPosts.filter((post) => post.id !== id));
            addSnackbar({
                message: '게시물을 삭제했어요.',
                severity: 'info',
                autoHideDuration: 3000,
                vertical: 'bottom',
                horizontal: 'center',
                type: 'toast',
            });
        } catch (error: any) {
            const errorMessage = utilsService.getErrorMessage(error);
            addSnackbar({
                message: errorMessage || 'Some thing went wrong',
                severity: 'error',
                autoHideDuration: 3000,
                vertical: 'bottom',
                horizontal: 'center',
                type: 'toast',
            });
        }
    };

    const updatePost = (updatedPost: any) => {
        setPosts((prevPosts) => prevPosts.map((post) => (post.id === updatedPost.id ? updatedPost : post)));
    };

    const refresh = async () => {
        await fetchPosts(selectedButton, true);
    };

    const updatePostField = (postId: string, field: keyof PostModel, newValue: any) => {
        setPosts((prevPosts) =>
            prevPosts.map((post) => {
                if (post.id === postId) {
                    const updatedPost = { ...post };
                    updatedPost[field] = newValue;
                    return Object.assign(Object.create(Object.getPrototypeOf(post)), updatedPost);
                }
                return post;
            })
        );
    };

    const onRetryWhenHasError = useCallback(() => {
        setCursor(null);
        setPosts([]);
    }, [selectedButton, type]);

    return { posts, totalPosts: totalPosts.current, refresh, isLoading, hasNext, hasError, loadMore, deletePost, updatePost, updatePostField, onRetryWhenHasError, hiddenPost };
};

export default usePosts;
