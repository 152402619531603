import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import { Box, CircularProgress, useTheme } from '@mui/material';
import CenteredBox from '@/app/base-ui/CenterBox';
import BaseCard from '@/app/base-ui/BaseCard';

interface MediaProps {
    loading?: boolean;
}

function Media(props: MediaProps) {
    return (
        <Card sx={{ boxShadow: 'none', width: '100%', borderRadius: '0', paddingBottom: 0, background: '#FFFFFF' }}>
            <Box sx={{ width: '100%', paddingX: { mobile: '20px', tablet: '50px' } }}>
                <CardHeader
                    sx={{
                        p: 0,
                        height: '80px',
                        gap: '15px',
                        '& .MuiCardHeader-avatar': { marginRight: 0 },

                        '.MuiCardHeader-title': {
                            marginBottom: 2,
                        },
                    }}
                    avatar={
                        <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                            sx={{ background: '#F1F1F1' }}
                        />
                    }
                    action={null}
                    title={
                        <Skeleton
                            animation="wave"
                            height={'12px'}
                            width="80%"
                            variant="rectangular"
                            sx={{ borderRadius: '100px', background: '#F1F1F1' }}
                        />
                    }
                    subheader={
                        <Skeleton
                            animation="wave"
                            height={'12px'}
                            width="40%"
                            variant="rectangular"
                            sx={{ borderRadius: '100px', background: '#F1F1F1' }}
                        />
                    }
                />
            </Box>
            <Skeleton
                sx={{ height: 210, background: '#F1F1F1' }}
                animation="wave"
                variant="rectangular"
            />
            <CardContent sx={{ gap: 4.5, display: 'flex', flexDirection: 'column' }}>
                <CenteredBox sx={{ width: '100%', justifyContent: 'space-between', lineHeight: '100%' }}>
                    <Skeleton
                        animation="wave"
                        height={'24px'}
                        width="55%"
                        variant="rectangular"
                        sx={{ borderRadius: '100px', background: '#F1F1F1' }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="circular"
                        height={24}
                        width={24}
                        sx={{ background: '#F1F1F1' }}
                    />
                </CenteredBox>
                <CenteredBox sx={{ flexDirection: 'column', gap: '9px' }}>
                    <Skeleton
                        animation="wave"
                        height={'12px'}
                        width="100%"
                        variant="rectangular"
                        sx={{ borderRadius: '100px', background: '#F1F1F1' }}
                    />
                    <Skeleton
                        animation="wave"
                        height={'12px'}
                        width="100%"
                        variant="rectangular"
                        sx={{ borderRadius: '100px', background: '#F1F1F1' }}
                    />
                    <Skeleton
                        animation="wave"
                        height={'12px'}
                        width="100%"
                        variant="rectangular"
                        sx={{ borderRadius: '100px', background: '#F1F1F1' }}
                    />
                </CenteredBox>
                {/* <React.Fragment>
                    <Skeleton
                        animation="wave"
                        height={10}
                    />
                    <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                    />
                </React.Fragment> */}
            </CardContent>
        </Card>
    );
}

function LoadMoreFeeds(): React.ReactElement {
    return (
        <BaseCard sx={{ py: 2, display: 'flex', width: '100%', alignItems: 'center' }}>
            <Box
                width={'100%'}
                sx={{ pl: 2 }}
            >
                <Skeleton />
                <Skeleton animation="wave" />
            </Box>
        </BaseCard>
    );
}

function LoadMoreArticlesHome(): React.ReactElement {
    return (
        <BaseCard sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'start' }}>
            <Box>
                <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                />
            </Box>
            <Box width={'100%'}>
                <Skeleton />
                <Skeleton animation="wave" />
            </Box>
        </BaseCard>
    );
}

function LoadMoreListProduct(type: 'table' | 'grid' | null): React.ReactElement {
    return (
        <BaseCard sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'start', gap: 4 }}>
            {type == 'table' ? (
                <>
                    <Skeleton
                        variant="rectangular"
                        width={96}
                        height={96}
                        sx={{ borderRadius: 4 }}
                    />
                    <Box
                        width={'80%'}
                        sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}
                    >
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </Box>
                </>
            ) : (
                <Skeleton
                    variant="rectangular"
                    width={160}
                    height={160}
                    sx={{ borderRadius: 4 }}
                />
            )}
        </BaseCard>
    );
}

function LoadingListPost() {
    const [amount, setAmount] = useState<number>(0);

    useEffect(() => {
        const updateAmount = () => {
            const cardHeight = 210 + 40 + 16 + 24; // Media component's skeleton height + padding/margins
            const availableHeight = window.innerHeight;
            const calculatedAmount = Math.floor(availableHeight / cardHeight);
            setAmount(calculatedAmount);
        };

        updateAmount();
        window.addEventListener('resize', updateAmount);

        return () => {
            window.removeEventListener('resize', updateAmount);
        };
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            {Array.from({ length: amount }, (_, index) => (
                <Box
                    key={index}
                    sx={{
                        py: 1,
                    }}
                >
                    <Media />
                </Box>
            ))}
        </Box>
    );
}

function LoadingCircular() {
    return (
        <CenteredBox>
            <CircularProgress />
        </CenteredBox>
    );
}

function LoadingMoreComments() {
    return (
        <Card sx={{ maxWidth: 345, boxShadow: 'none', background: 'none' }}>
            <CardHeader
                avatar={
                    <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                    />
                }
                title={
                    <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                    />
                }
                subheader={
                    <Skeleton
                        animation="wave"
                        height={10}
                        width="40%"
                    />
                }
            />
        </Card>
    );
}

function LoadingButtonSwiper() {
    return (
        <>
            <Skeleton
                animation="wave"
                height={36}
                variant="rectangular"
                width="51px"
                sx={{ borderRadius: '100px' }}
            />
            <Skeleton
                animation="wave"
                height={36}
                variant="rectangular"
                sx={{ borderRadius: '100px', background: '#FFF' }}
                width="120px"
            />
            <Skeleton
                animation="wave"
                height={36}
                sx={{ borderRadius: '100px', background: '#FFF' }}
                variant="rectangular"
                width="120px"
            />
        </>
    );
}

function LoadingListBaseForm() {
    const theme = useTheme();
    return (
        <CenteredBox sx={{ flexDirection: 'column', px: { mobile: 5, tablet: 12.5 } }}>
            {Array(5)
                .fill(0)
                .map((s, key) => (
                    <Box
                        sx={{ flexDirection: 'column', justifyContent: 'start', width: '100%', py: 5, alignItems: 'start', borderBottom: `1px solid ${theme.palette.grayscale.gray03}` }}
                        key={key}
                    >
                        <Box>
                            <Skeleton
                                animation="wave"
                                height={'24px'}
                                width={80}
                                variant="rectangular"
                                sx={{ borderRadius: '100px', mb: '11px' }}
                            />
                        </Box>
                        <CardHeader
                            sx={{
                                p: 0,
                                gap: '10px',
                                '& .MuiCardHeader-avatar': { marginRight: 0 },

                                '.MuiCardHeader-title': {
                                    marginBottom: 2,
                                },
                            }}
                            avatar={
                                <Skeleton
                                    animation="wave"
                                    variant="circular"
                                    width={40}
                                    height={40}
                                    sx={{ background: '#F1F1F1' }}
                                />
                            }
                            title={
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                    <Skeleton
                                        animation="wave"
                                        height={'12px'}
                                        width="80%"
                                        variant="rectangular"
                                        sx={{ borderRadius: '100px', background: '#F1F1F1' }}
                                    />
                                    <Skeleton
                                        animation="wave"
                                        height={'12px'}
                                        width="20%"
                                        variant="rectangular"
                                        sx={{ borderRadius: '100px', background: '#F1F1F1' }}
                                    />
                                </Box>
                            }
                            subheader={
                                <Skeleton
                                    animation="wave"
                                    height={'12px'}
                                    width="40%"
                                    variant="rectangular"
                                    sx={{ borderRadius: '100px', background: '#F1F1F1' }}
                                />
                            }
                        />
                        {/* <CenteredBox sx={{ justifyContent: 'start', gap: 2.5, width: '100%' }}>
                            
                                <Skeleton
                                    animation="wave"
                                    variant="circular"
                                    width={40}
                                    height={40}
                                    sx={{ background: '#F1F1F1' }}
                                />
                         
                            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                                <Skeleton
                                    animation="wave"
                                    height={12}
                                    width={'80%'}
                                    variant="rectangular"
                                    sx={{ borderRadius: '100px' }}
                                />
                                <Skeleton
                                    animation="wave"
                                    height={12}
                                    width={'20%'}
                                    variant="rectangular"
                                    sx={{ borderRadius: '100px' }}
                                />
                            </Box>
                        </CenteredBox> */}
                    </Box>
                ))}
        </CenteredBox>
    );
}

export const Loading = {
    LoadingListPost,
    LoadingListBaseForm,
    LoadingButtonSwiper,
    LoadingCircular,
    LoadMoreFeeds,
    LoadingMoreComments,
    LoadMoreArticlesHome,
    LoadMoreListProduct,
};
